import apiInstance from './index'
import queryParams from './queryParams'

export default {
  async getRoles(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`roles${query}`)
  },
  async getRolesDifferentFromTheCurrentRole(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`roles/different-from-the-current-role${query}`)
  },
  async storeRole(data) {
    return await apiInstance.post('roles', data)
  },
  async updateRole(role, data) {
    return await apiInstance.put(`roles/${role}`, data)
  },
  async statusRole(role) {
    return await apiInstance.put(`roles/status/${role}`)
  },
  async deleteRole(role) {
    return await apiInstance.delete(`roles/${role}`)
  },
  async getRole(role, paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`roles/${role}${query}`)
  },
}